import {createSlice} from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import {dispatch} from "../index";

import {DefaultRootStateProps, WatchlistStateProps} from "types/pmp";

const initialState: DefaultRootStateProps["watchlist"] = {
    error: null,
    watchlistDescription: "",
    portID: "",
    watchlistPortfolioGrouping: null,
    watchlistBenchmark: null,
    watchlistPortfolioAllocation: null,
    watchlistBmkRiskStats: null,
    watchlistPortPerf: null,
    watchlistSummaryPerformance: null,
    watchlistPerfSummary: null,
    watchlistPortfolioStyle: null,
    watchlistPortfolioFundamental: null,
    viewPortfolioWatchlist: null,
    removeWatchlist: null,
    watchlistTagName: null,
    watchlistScores: [],
    watchlistBenchmarkScores: [],
    watchlistCumlBmkPerf: null,
    watchlistPortAlign: null,
    watchlistPerformancePortBmk: null,
    createBCJTson: null,
    validateBCT:null,
    watchlistAllocationTableSuccess: null,
    watchlistCumulPortPerf: null,
    chatResponse: null,
    startEndDates: null,
    userInfoError: null,
    generateExcelFile:null,
    timeFrame: [],
    userNotifications:[],
    equityBmkReturns:null,
    rebalancePortfolio:null,
    downloadBotConfig:null,
    driftAnalysisChart:null,
    suggestedWeight:null,
   
};
const slice = createSlice({
        name: "watchlist",
        initialState,
        reducers: {
            hasError(state, action) {
                state.error = action.payload;
            },

            getWatchlistDescriptionSuccess(state, action) {
                state.watchlistDescription = action.payload;
            },
            getWatchlistBenchmarkPerfSuccess(state, action) {
                state.watchlistBenchmark = action.payload;
            },
            getWatchlistCumulBmkPerfSuccess(state, action) {
                state.watchlistCumlBmkPerf = action.payload;
            },
            getWatchlistPortfolioAllocationsSuccess(state, action) {
                state.watchlistPortfolioAllocation = action.payload;
            },
            getWatchlistPortfiloBmkRiskStatsSuccess(state, action) {
                state.watchlistBmkRiskStats = action.payload;
            },
            getWatchlistPortPerfSuccess(state, action) {
                state.watchlistPortPerf = action.payload;
            },
            getWatchlistCumulPerfSuccess(state, action) {
                state.watchlistCumulPortPerf = action.payload;
            },

            getWatchlistPortfolioGroupingSuccess(state, action) {
                state.watchlistPortfolioGrouping = action.payload;
            },
            getWatchlistPerfBmkRiskStatsSuccess(state, action) {
                state.watchlistPerformancePortBmk = action.payload;
            },
            getWatchlistSummaryPerformanceSuccess(state, action) {
                state.watchlistSummaryPerformance = action.payload;
            },
            getWatchlistPerfSummarySuccess(state, action) {
                state.watchlistPerfSummary = action.payload;
            },
            getWatchlistPortfolioStyleSuccess(state, action) {
                state.watchlistPortfolioStyle = action.payload;
            },
            getWatchlistPortfolioFundamentalSuccess(state, action) {
                state.watchlistPortfolioFundamental = action.payload;
            },
            getViewPortfolioWatchlistSuccess(state, action) {
                state.viewPortfolioWatchlist = action.payload;
            },
            removePortfolioWatchlistSuccess(state, action) {
                state.removeWatchlist = action.payload;
            },
            getWatchlistTagNameSuccess(state, action) {
                state.watchlistTagName = action.payload;
            },
            getWatchlistScoresSuccess(state, action) {
                const {portfolioId, data} = action.payload;
                state.watchlistScores.push({portfolioId, data});
            },

            getWatchlistBenchmarkScoresSuccess(state, action) {
                const {portfolioId, data} = action.payload;
                state.watchlistBenchmarkScores.push({portfolioId, data});
            },
            getWatchlistPortAlignSuccess(state, action) {
                state.watchlistPortAlign = action.payload;
            },
            getCreateBCTfromJsonSuccess(state, action) {
                state.createBCJTson = action.payload;
            },
            getWatchlistAllocationTableSuccess(state, action) {
                state.watchlistAllocationTableSuccess = action.payload;
            },
            getChatApiSuccess(state, action) {
                state.chatResponse = action.payload;
            },
            getDateSuccess(state, action) {
                state.startEndDates = action.payload;
            },
            getTimeFrameMetricsSuccess(state,action){
                const{portfolioId,data,selectedValue}=action.payload;
                state.timeFrame.push({portfolioId,data,selectedValue});
            },
            getUserInfoSuccess(state, action) {
                state.userInfoError = action.payload;
            },
            generateExcelSuccess(state,action){
                state.generateExcelFile = action.payload;
            },
            getfetchNotifications(state,action){
                state.userNotifications = action.payload;
            },
            getEquityBmkChartSuccess(state,action){
                state.equityBmkReturns =action.payload;
            },
            validateBCTfromJsonSuccess(state,action){
                state.validateBCT= action.payload;
            },
            getDownloadSuccess(state,action){
                state.downloadBotConfig= action.payload;
            },
            rebalancePortfolioSuccess(state,action){
                state.rebalancePortfolio=action.payload;
            },
            rebalanceWeightSuccess(state,action){
                state.suggestedWeight=action.payload;
            },
            
            getDriftAnalysisSuccess(state,action){
                state.driftAnalysisChart=action.payload;
            }

        }
    });

export default slice.reducer;

export function viewWatchlist(token: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.viewPortfolioWatchList/%5B%5D`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getViewPortfolioWatchlistSuccess(r));
            });
        });
    };
}

export function removeWatchlist(token: any, portID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.removeCustomPortfolioFromWatchList/["${portID}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {

                dispatch(slice.actions.removePortfolioWatchlistSuccess(r));
            });
        });
    };
}

export function getPortfolioWatchlistDescription(token: any, portfolioID: any) {
    return async () => {
        try {
            await fetch(`https://api.versaquant.com:5000/run3/pr.getPortfolioDescription/["${portfolioID}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(async response => {
                {
                    response.json().then(async result => {
                        dispatch(slice.actions.getWatchlistDescriptionSuccess(result));
                    });
                }
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getWatchlistPortfolioGroupings(token: any, portfolioID: any, Type: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortGroupings/["${portfolioID}","${Type}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPortfolioGroupingSuccess(result));
            });
        });
    };
}

export function getWatchlistBenchmarkPerf(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getBenchmarkPerf/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistBenchmarkPerfSuccess(result));
            });
        });
    };
}

export function getWatchlistCumlBmkPerf(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pm.getBenchmarkPerf/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistCumulBmkPerfSuccess(result));
            });
        });
    };
}

export function getWatchlistPortfolioAllocations(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortfolioAllocation/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPortfolioAllocationsSuccess(result));
            });
        });
    };
}

export function getWatchlistPortPerf(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortPerf/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPortPerfSuccess(result));
            });
        });
    };
}

export function getWatchlistPortCumulPerf(token: any, portfolioID: any) {
    return async (dispatch: any) => {
        try {
            let url = `https://api.versaquant.com:5000/run3/pm.getPortPerf/["${portfolioID}"]`;
            const response = await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            dispatch(slice.actions.getWatchlistCumulPerfSuccess(result));
        } catch (error) {
            // Handle errors, e.g., dispatch an action to set an error state
            console.error('Error fetching watchlist port cumulative performance:', error);
            // Dispatch an action to set an error state if needed
        }
    };
}

export function getWatchlistPortfiloBmkRiskStats(token: any, portfolioID: any, BmkType: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortfolioBmkRiskStats/["${portfolioID}","${BmkType}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPortfiloBmkRiskStatsSuccess(result));
            });
        });
    };
}

export function getWatchlistPerfBmkRiskStats(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pm.getPortBmkMetrics/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPerfBmkRiskStatsSuccess(result));
            });
        });
    };
}

export function getWatchlistSummmaryPerformance(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getSummaryPerformance/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistSummaryPerformanceSuccess(result));
            });
        });
    };
}

export function getWatchlistPerfSummary(token: any, jobtype: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getSummaryPerformance/["${jobtype}","${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPerfSummarySuccess(result));
            });
        });
    };
}

export function getWatchlistPortfolioStyles(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortfolioStyles/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPortfolioStyleSuccess(result));
            });
        });
    };
}

export function getWatchlistPortfolioFundamentals(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortfolioFundamentals/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPortfolioFundamentalSuccess(result));
            });
        });
    };
}

export function getWatchlistTagName(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortMetaData/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistTagNameSuccess(result));
            });
        });
    };
}

export function getWatchlistScores(token: any, portfolioID: any) {
    return async (dispatch: any) => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortfolioScores/["${portfolioID}"]`;
        try {
            const response = await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            if (Array.isArray(result)) {
                // Find the specific portfolio data
                const portfolioData = result.find((data: any) => data.portID === portfolioID);

                // Extract bmkEquities scores and the Score
                const bmkEquitiesScores = {
                    Score: portfolioData?.bmkEquities?.Score || 0,
                    Score_divMDPew: portfolioData?.bmkEquities?.Score_divMDPew || 0,
                    Score_AnnualizedRet: portfolioData?.bmkEquities?.Score_AnnualizedRet || 0,
                    Score_AnnualizedVol: portfolioData?.bmkEquities?.Score_AnnualizedVol || 0,
                    Score_PSRpt5TimeWeighted: portfolioData?.bmkEquities?.Score_PSRpt5TimeWeighted || 0,
                    Score_MaximumDrawDown: portfolioData?.bmkEquities?.Score_MaximumDrawDown || 0,
                };

                const portfolioScores = {
                    Score: portfolioData?.Score || 0,
                    Score_divMDPew: portfolioData?.Score_divMDPew || 0,
                    Score_AnnualizedRet: portfolioData?.Score_AnnualizedRet || 0,
                    Score_AnnualizedVol: portfolioData?.Score_AnnualizedVol || 0,
                    Score_PSRpt5TimeWeighted: portfolioData?.Score_PSRpt5TimeWeighted || 0,
                    Score_MaximumDrawDown: portfolioData?.Score_MaximumDrawDown || 0,
                };

                // Dispatch the scores as needed
                dispatch(slice.actions.getWatchlistScoresSuccess({portfolioId: portfolioID, data: portfolioScores}));
                dispatch(slice.actions.getWatchlistBenchmarkScoresSuccess({
                    portfolioId: portfolioID,
                    data: bmkEquitiesScores
                }));
            } else {
                // Handle the case where result is not an array
                console.error("Result is not an array:", result);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle the error as needed
        }
    };
}

export function getWatchlistPortAlign(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getPortAlign/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistPortAlignSuccess(result));

            });
        });
    };
}

export function getCreateBCTfromJson(token: any, formattedData: any, investmentHorizon: any, risk: any, initInvAmount: any, investGoal: any, accountType: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pc.createBCTFromJSON/[${formattedData}, ${investmentHorizon}, ${risk}, ${initInvAmount}, "${investGoal}", "${accountType}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getCreateBCTfromJsonSuccess(result));

            });
        });
    };
}

export function getWatchlistAllocationTable(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getCurrentBotConfig/["${portfolioID}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getWatchlistAllocationTableSuccess(result));
            });
        });
    };
}

export function chatApi(token: any, portID: any, question: any, selectedOption: any) {
    return async (dispatch: any) => {
        let url = `https://api.versaquant.com:5000/run3/pr.askAlpha/["${portID}","${question}","${selectedOption}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getChatApiSuccess(r));
            });
        });
    };
}


export function getWatchlistDates(token: any, id: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pr.getBTDates/["${id}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {

                dispatch(slice.actions.getDateSuccess(r));
            });
        });
    };
}
export function TimeFrameMetrics(token: any, portfolioID: any, Ndays: any) {
    return async (dispatch: any) => {
        let url = `https://api.versaquant.com:5000/run3/pm.getPortfolioMetrics/["${portfolioID}","${Ndays}"]`;
        try {
            const response = await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Assuming data is an array of time frame metrics
            dispatch(slice.actions.getTimeFrameMetricsSuccess({portfolioId: portfolioID, data: data,selectedValue:Ndays}));
        } catch (error) {
            console.error('Error fetching time frame metrics:', error);
        }
    };
}


export function getUserInfoError(token: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/userInfo/`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {

                dispatch(slice.actions.getUserInfoSuccess(r));
            });
        });
    };
}
export function generateExcel(token, portfolioID) {
    return async (dispatch) => {
        const url = `https://api.versaquant.com:5000/generate_excel`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ portID: portfolioID })
            });

            if (response.ok) {
                const blob = await response.blob();
                const contentDisposition = response.headers.get('content-disposition');
                const presentDate = new Date().toISOString().split('T')[0]; // code by
                let filename = `Validation_${portfolioID}_Date_${presentDate}.xlsx`;
                
                
                if (contentDisposition && contentDisposition.includes('filename=')) {
                    const match = contentDisposition.match(/filename="([^"]+)"/);
                    if (match) {
                        filename = match[1];
                    }
                }

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
                dispatch(slice.actions.generateExcelSuccess('Excel file generated successfully'));
            } else {
                console.error('Failed to generate Excel file');
                // Handle error appropriately here
              
            }
        } catch (error) {
            console.error('Error generating Excel file:', error);
            // Handle error appropriately here
           
        }
    };

    
}

export function fetchNotifications(token: any) { 
    return async () => {
        let url = 'https://api.versaquant.com:5003/api/v1/notifications?all=false';
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getfetchNotifications(result));
            });
        });
    };
}

    export function getEquityBmkChart(token: any, portfolioID: any, isWatchListPortfolio: any) {
        return async (dispatch: any) => {
            let url = `https://api.versaquant.com:5000/run3/pr.getEquityBmkReturns/["${portfolioID}",${isWatchListPortfolio}]`;
            try {
                const response = await fetch(url, {
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                dispatch(slice.actions.getEquityBmkChartSuccess(data));
            } catch (error) {
                console.error('Error fetching time frame metrics:', error);
            }
        };
    }


    export function validateBCTfromJson(token: any,portfolioID:any, formattedData: any) {
        return async () => {
            let url = `https://api.versaquant.com:5000/run3/pr.validateBTC/["${portfolioID}",${(formattedData)}]`;
            await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    dispatch(slice.actions.validateBCTfromJsonSuccess(result));
    
                });
            });
        };
    }
    export function updateRebalancePortfolio(token: any,portfolioID:any, jobID: any) {
        return async () => {
            let url = `https://api.versaquant.com:5000/run3/pr.rebalancePortfolio/["${portfolioID}","${(jobID)}"]`;
            await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    dispatch(slice.actions.rebalancePortfolioSuccess(result));
    
                });
            });
        };
    }
    export function getPortfolioDriftAnalysis(token: any,portfolioID:any,category:any) {
        return async () => {
            let url = `https://api.versaquant.com:5000/run3/pr.getPortfolioDriftAnalysis/["${portfolioID}","${category}"]`;
            await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    dispatch(slice.actions.getDriftAnalysisSuccess(result));
    
                });
            });
        };
    }
    export function downloadBotConfig(token: string, portID: string) {
        return async () => {
            try {
                let response = await fetch(`https://api.versaquant.com:5000/download_bot_config`, {
                    method: 'POST',
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        portID: portID
                    })
                });
    
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    
                let result = await response.json();
    
                dispatch(slice.actions.getDownloadSuccess(result));
    
            } catch (error) {
                console.error("error is ", error);
                dispatch(slice.actions.hasError(error));
            }
        }
    }
    export function getRebalancePortfolioWeights(token: any,portfolioID:any) {
        return async () => {
            let url = `https://api.versaquant.com:5000/run3/pr.getRebalancePortfolioWeights/["${portfolioID}"]`;
            await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    dispatch(slice.actions.rebalanceWeightSuccess(result));
    
                });
            });
        };
    }